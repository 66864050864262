import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Heading48, Paragraph18 } from "../../styles/PageStyles"
import ReactMarkdown from "react-markdown"

const PageHeaderId = ({ pageTitle, pageDescription }) => {
  return (
    <Header>
      <Banner>
        <StaticImage src="../../multimedia/Header.png" alt="Projects" />
      </Banner>
      <div className="heading">
        <Heading48 data-aos="fade-right">{pageTitle}</Heading48>
      </div>
      <div
        className="desc"
        data-aos="fade-up"
        data-aos-delay={1000}
        data-aos-duration={800}
      >
        <Paragraph18>
          <ReactMarkdown>{pageDescription}</ReactMarkdown>
        </Paragraph18>
        <div className="horizontal-line" />
      </div>
    </Header>
  )
}

export default PageHeaderId

const Header = styled.div`
  color: white;
  background: #f7f8fc;
  position: relative;

  .heading,
  .desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .desc {
    position: relative;
    z-index: 3;
    top: -80px;
  }

  .heading {
    position: absolute;
    top: 200px;
    left: 0;
    right: 0;

    h1 {
      margin-bottom: 50px;
    }
  }

  .desc {
    background: #fff;
    margin: 0 10%;
    padding: 25px 125px;
    box-shadow: 5px 5px 30px -10px rgba(0, 0, 0, 0.2);

    p {
      font-weight: 500;
      text-align: center;
      color: #5b5d5e;
    }

    .horizontal-line {
      border-bottom: 3px solid #d1e869 !important;
      width: 500px;
      margin-top: 25px;

      @media only screen and (max-width: 768px) {
        width: 80%;
      }
    }

    @media only screen and (max-width: 768px) {
      padding: 25px 10%;
    }
  }
`

const Banner = styled.div`
  padding-top: 90px;
  display: flex;
  position: relative;
  height: 415px;
  overflow: hidden;
  z-index: 0;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
    width: 100%;
    z-index: -1;
    object-fit: cover;
    object-position: center 70%;
  }
`
