import React from "react"
import { graphql } from "gatsby"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { IntlProvider } from "react-intl"
import "intl"
import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import PageHeader from "../components/indonesian/PageHeaderId"
import CareersContent from "../components/indonesian/careers/CareersContentId"

const CareersPageId = ({ data, location, i18nMessages }) => {
  const url = location.pathname
  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${langKey}/`
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url))

  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      <Layout
        seoTitle="Karir"
        seoKeywords="Lulusan Baru, Karir, Lowongan, Karir Teknik, Profesional, Lowongan Berpengalaman, Lowongan Pekerjaan JFEEI, Lowongan Pekerjaan Teknik, Lowongan Pekerjaan JFE Engineering Indonesia, Perekrutan JFE Engineering Indonesia, Kehidupan di JFE, Lowongan Pekerjaan JFEEI, Lowongan Pekerjaan Teknik, Lowongan Pekerjaan JFE Engineering Indonesia, Perekrutan JFE Engineering Indonesia, Lamaran Kerja JFEEI"
      >
        <Navbar langKey={langKey} langs={langsMenu} url={url} />
        <PageHeader
          pageTitle="Karir"
          pageDescription={data.markdownRemark.frontmatter.header?.description}
        />
        <CareersContent />
        <Footer langKey={langKey} />
      </Layout>
    </IntlProvider>
  )
}

export default CareersPageId

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "careers-id" } }) {
      frontmatter {
        header {
          description
        }
      }
    }
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
