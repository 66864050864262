import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import Testimonial from "../TestimonialId"
import JobApplicationForm from "../JobApplicationFormId"
import CircleBg from "../../../../static/svg/Large-Circle.svg"
import CircleBgMedium from "../../../../static/svg/Medium-Circle.svg"
import { GradientFooterBg } from "../../Footer"
import {
  Heading32,
  Heading40,
  Paragraph18,
  StyledButton,
} from "../../../styles/PageStyles"

const CareersContentId = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "careers-id" } }) {
        frontmatter {
          joinUs {
            hidden
            title
            professional {
              hidden
              title
              description
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            freshGraduate {
              hidden
              title
              description
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          dropYourResumeBg {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  `)

  const { frontmatter } = data.markdownRemark
  const { professional } = frontmatter.joinUs
  const { freshGraduate } = frontmatter.joinUs

  return (
    <StyledContainer fluid>
      <div id="join-us">
        {!frontmatter.joinUs?.hidden && (
          <>
            <Heading40 data-aos="zoom-out">
              {frontmatter.joinUs?.title}
            </Heading40>
            {!professional?.hidden && (
              <StyledRow
                id="professional"
                className="professional-wrapper"
                reversed
              >
                <StyledCol
                  className="career-desc-col"
                  lg={6}
                  data-aos="fade-right"
                >
                  <Heading32>{professional?.title}</Heading32>
                  <Paragraph18>
                    <ReactMarkdown>{professional?.description}</ReactMarkdown>
                  </Paragraph18>
                  <StyledButton to="/id/careers/professional/">
                    <Paragraph18>Lihat Lowongan</Paragraph18>
                  </StyledButton>
                </StyledCol>
                <StyledCol className="img-col" lg={6} data-aos="fade-left">
                  <GatsbyImage
                    className="professional-img"
                    image={getImage(professional?.image)}
                    alt={professional?.title}
                    layout="fullWidth"
                  />
                </StyledCol>
              </StyledRow>
            )}
            {!freshGraduate?.hidden && (
              <StyledRow id="fresh-grad" className="fresh-grad-wrapper">
                <StyledCol
                  className="img-col"
                  lg={6}
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <GatsbyImage
                    className="fresh-grad-img"
                    image={getImage(freshGraduate?.image)}
                    alt={freshGraduate?.title}
                    layout="fullWidth"
                  />
                </StyledCol>
                <StyledCol
                  className="career-desc-col"
                  lg={6}
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  <Heading32>{freshGraduate?.title}</Heading32>
                  <Paragraph18>
                    <ReactMarkdown>{freshGraduate?.description}</ReactMarkdown>
                  </Paragraph18>
                  <StyledButton to="/id/careers/fresh-graduate/">
                    <Paragraph18>Lihat Lowongan</Paragraph18>
                  </StyledButton>
                </StyledCol>
              </StyledRow>
            )}
          </>
        )}
      </div>
      <CircleBg className="circle-bg" />
      <CircleBgMedium className="circle-bg-medium" />
      <div id="life-at-jfeei">
        <Testimonial />
      </div>
      <DropYourResume id="drop-your-resume">
        <GatsbyImage
          className="form-bg"
          image={getImage(frontmatter.dropYourResumeBg)}
          alt="Drop your resume"
        />
        <JobApplicationForm show={true} />
      </DropYourResume>
      <GradientFooterBg marginLeft marginLeftTablet />
    </StyledContainer>
  )
}

export default CareersContentId

const StyledContainer = styled(Container)`
  padding: 0 10% 100px 10%;
  position: relative;
  z-index: 1;

  h1 {
    text-align: center;
    margin-bottom: 50px;
  }

  .circle-bg {
    position: absolute;
    left: -696px;
    bottom: -200px;
    z-index: -1;
  }

  .circle-bg-medium {
    position: absolute;
    right: -416px;
    top: 250px;
    z-index: -1;
  }

  .professional-wrapper {
    padding-bottom: 100px;
  }

  @media screen and (max-width: 1000px) {
    .professional-wrapper {
      padding-bottom: 50px;
    }

    .professional-wrapper,
    .fresh-grad-wrapper {
      .career-desc-col {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @media screen and (max-width: 769px) {
    padding: 0 5% 100px 5%;
  }

  @media screen and (max-width: 426px) {
    padding: 0 5% 50px 5%;
  }
`

const StyledRow = styled(Row)`
  display: flex;
  margin: 0;

  .img-col {
    height: 30vw;

    @media only screen and (max-width: 1000px) {
      height: 80vw;
      width: 100%;
    }
  }

  @media screen and (max-width: 1000px) {
    flex-direction: ${({ reversed }) =>
      reversed ? "column-reverse" : "column"};
    width: 100%;

    .professional-wrapper {
      padding-bottom: 50px;
    }
  }
`

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-weight: bold;
    text-align: left;
  }

  h1,
  p {
    margin-bottom: 25px;
  }

  .professional-img {
    border-radius: 75px 0;
  }

  .fresh-grad-img {
    border-radius: 0 75px;
  }

  @media only screen and (max-width: 1000px) {
    padding: 0;
    .professional-img,
    .fresh-grad-img {
      margin-bottom: 25px;
    }
  }
`

const DropYourResume = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  .form-bg {
    overflow: visible !important;

    img {
      max-width: 100%;
      max-height: 650px;
      position: absolute;
      left: -50% !important;
      border-radius: 20px;

      @media only screen and (max-width: 1440px) and (min-width: 1366px) {
        left: -70% !important;
        max-height: 450px;
      }

      @media only screen and (min-width: 2560px) {
        max-height: 1000px;
        left: -35%;
      }
    }
  }

  @media only screen and (max-width: 1365px) {
    flex-direction: column;

    .form-bg {
      img {
        top: 25px !important;
        left: 0 !important;
        border-radius: 20px;
      }
    }
  }

  @media only screen and (max-width: 426px) {
    h1 {
      text-align: left;
      margin-bottom: 25px;
    }
  }
`
